.content {
  img {

    &.right {
      clear: both;
      float: right;
      margin-left: 20px;
      margin-bottom: 20px;
    }

    &.left {
      clear: both;
      float: left;
      margin-right: 20px;
      margin-bottom: 20px;
    }

    @media (max-width: 1300px) {
      max-width: 100%;
      height: auto;
      margin: auto;
    }

    @media (max-width: 600px) {
      float: none !important;
      &.right {
        margin-left: 0;
      }
      &.left {
        margin-right: 0;
      }
    }
  }

  .lightbox {
    margin: 0;
    margin-top: 14px;
    margin-bottom: 14px;
    border-radius: 1px;
    padding: 32px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, .2);
    margin: 16px 0;
    width: 100%;
    display: flex;
    justify-content: center;

    img {
      max-width: 100%;
      height: auto;
      padding: 8px;
      margin: auto;
      box-shadow: 0 2px 2px rgba(10, 16, 20, 0.24), 0 0 2px rgba(10, 16, 20, 0.12);
      border-radius: 4px;
    }
  }
}
