@use '../../constants';
@use '../../mixins';

footer {
  position: relative;
  @include mixins.line-height(24);
  flex: 1;
  padding: 48px;
  z-index: 0;
  font-weight: 300;

  aio-footer {
    position: relative;
    z-index: 0;

    & > * {
      max-width: 50em;
    }

    .footer-block {
      margin: 0 24px;
      vertical-align: top;
    }

    a {
      text-decoration: none;
      z-index: 20;
      position: relative;

      &:hover {
        text-decoration: underline;
      }

      &:visited {
        text-decoration: none;
      }

      &:focus {
        // `outline-offset` is not applied on Chrome on Windows, if `outline-style` is `auto.
        outline: 1px solid rgba(constants.$white, 0.8);
        outline-offset: 2px;
      }
    }

    h3 {
      @include mixins.font-size(16);
      text-transform: uppercase;
      font-weight: 400;
      margin: 8px 0 12px;

      @media (max-width: 600px) {
        @include mixins.font-size(14);
      }
    }

    p {
      text-align: center;
      margin: 10px auto 5px;

      @media (max-width: 480px) {
        text-align: left;
      }
    }

    div.grid-fluid {
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      justify-content: center;
      text-align: left;
      margin: 0 auto 40px;

      ul {
        list-style-position: inside;
        padding: 0px;
        margin: 0px;

        li {
          list-style-type: none;
          padding: 4px 0;
          text-align: left;
        }
      }

      @media (max-width: 32rem) {
        flex-direction: column;

        .footer-block {
          margin: 8px 24px;
        }
      }

      @media not all and (min-resolution:.001dpcm) { // hack to detect safari
        // tweak needed as safari handles rem based media queries differently
        @media (max-width: 50rem) {
          flex-direction: column;
        }
      }
    }
  }

  &::after {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: url("/assets/images/logos/angular/angular_whiteTransparent_withMargin.png")
        top 0 left 0 repeat,
      url("/assets/images/logos/angular/angular_whiteTransparent_withMargin.png")
        top 80px left 160px repeat;
    opacity: 0.05;
    background-size: 320px auto;
  }
}
