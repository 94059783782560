@use '../../mixins';

.presskit-container {
  padding: 0 32px 32px 32px;

  img {
    height: 128px;
    width: auto;
  }

  .presskit-section {
    &:not(:first-child) {
      margin-top: 4rem;
      padding-top: 2rem;
    }

    .presskit-icon-group {
      display: flex;
      flex-wrap: wrap;

      .presskit-icon-item {
        align-items: center;
        display: flex;
        margin: 1rem;
        width: calc(50% - 2rem);

        @media screen and (max-width: 600px) {
          align-items: flex-start;
          flex-direction: column;
          margin-bottom: 2rem;
        }

        .presskit-image-container {
          flex: none;
          margin-right: 2rem;

          @media (max-width: 600px) {
            width: 100%;
            margin-right: 0;
          }

          .transparent-img, .transparent-img-inverse {
            border-radius: 50%;
          }
        }

        .presskit-links-container {
          list-style-type: none;
          margin-bottom: 0;
          padding: 0;

          a {
            padding-right: 3rem;
            position: relative;

            &::after {
              content: "cloud_download";
              font-family: "Material Icons";
              @include mixins.font-size(24);
              position: absolute;
              right: 0;
            }
          }
        }
      }
    }
  }
}
