@use '../../mixins';

table {
  margin: 24px 0px;
  border-radius: 2px;

  &.is-full-width {
    width: 100%;
  }

  &.is-fixed-layout {
    table-layout: fixed;
  }

  thead {
    vertical-align: middle;
    border-color: inherit;

    > tr {
      vertical-align: inherit;
      border-color: inherit;
    }

    > tr > th {
      @include mixins.font-size(12);
      font-weight: 500;
      padding: 8px 24px;
      text-align: left;
      text-transform: uppercase;
      @include mixins.line-height(28);
    }
  }

  tbody > tr {
    > th,
    > td {
      padding: 16px;
      text-align: left;
      @include mixins.line-height(24);
      vertical-align: top;

      @media (max-width: 480px) {
        &:before {
          // content: **ADD TABLE HEADER**;
          display: inline-block;
        }
      }

    }

    > td {
      @include mixins.letter-spacing(0.3);
    }

    > th {
      font-weight: 600;
      max-width: 100px;
    }
  }

  tbody > tr {
    &:last-child td {
      border: none;
    }
  }
}

.page-guide-cheatsheet {

  table tbody td {
    overflow: auto;
  }

  @media only screen and (max-width: 990px) {

    /* Force table to not be like tables anymore */
    table, thead, tbody, tfoot, tr, th, td {
      display: block;
      position: relative;
      max-width: 100%;
    }

    th {
      border-right: none;
    }

    th, td {
      &:not(:last-child) {
        border-bottom: none;
        padding-bottom: 0px;
      }
    }
  }
}

.page-events {
  tr > td, tr > th {
    width: 33%;
  }
}
