html, body {
  height: 100%;
}

.center-layout {
  margin: 0 auto;
  max-width: 62.5em;
}

.center-layout-wide {
  margin: 0 auto;
  max-width: 84em;
}

.github-links + .content h1 {
  max-width: 90%;
}

.clear {
  clear: both;
}

.l-flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.flex-center {
  display: flex;
  justify-content: center;
}

.center {
  text-align: center;
}

.visually-hidden {
  position: absolute !important;
  top: -9999px !important;
  left: -9999px !important;
}
