@use '../../mixins';

aio-contributor-list {
  .contributor-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  @media handheld and (max-width: 480px), screen and (max-width: 900px) {
    .grid-fluid {
      margin-left: 20px;
      margin-right: 20px;
      float: none;
      display: block;
      width: auto;
    }
  }
}

aio-contributor {
  margin: 8px;
  border-radius: 4px;
  box-shadow: 0 2px 2px rgba(10, 16, 20, 0.24), 0 0 2px rgba(10, 16, 20, 0.12);

  @media not all and (prefers-reduced-motion) {
    transition: all .3s;
  }

  @media (hover) {
    &:focus-within,
    &:hover {
      transform: translate3d(0,-3px,0);
      box-shadow: 0 8px 8px rgba(10, 16, 20, 0.24), 0 0 8px rgba(10, 16, 20, 0.12);

      .contributor-image {
        transform: scale(1.05);
      }

      .contributor-info {
        opacity: 1;
      }
    }
  }

  .contributor-info {
    height: 100%;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    /* There is no point in hiding this if we can't hover to show it. */
    @media (hover) { opacity: 0; }
    border-radius: 50%;

    .info-item {
      display: flex;
      @include mixins.font-size(14);
      font-size: clamp(10px, 1.4rem, 30px);
      font-weight: 500;
      margin: 0.8rem;
      padding: 0.5rem;
      width: 100%;
      justify-content: center;

      &:hover {
        text-decoration: none;
      }

      &.icon {
        $size: 23px;
        min-width: $size;
        width: $size;

        mat-icon {
          height: $size;
          width: $size;
          font-size: $size;

          &[svgicon] {
            svg {
              height: 100%;
              width: 100%;
            }
          }

          &.link-icon {
            transform: rotateZ(45deg);
          }
        }
      }
    }

    button {
      background: transparent;
      border: none;
      cursor: pointer;
    }
  }

  .contributor-card {
    width: 25rem;
    height: 27rem;
    max-width: 310px;
    max-height: 340px;
    transform-style:preserve-3d;

    @media not all and (prefers-reduced-motion) {
      transition:transform ease 500ms;
    }

    h3 {
      margin: 0.8rem 0;
      text-align: center;
    }

    .card-front, .card-back {
      cursor: pointer;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      justify-content: center;
      align-items: center;
      border: none;
      background: transparent;
      padding: 0 1.5rem;
    }

    .card-back {
      padding: 16px 24px;
      transform:rotateY(180deg);

      section {
        display: none;
      }

      p {
        margin: 0.8rem 0;
        @include mixins.font-size(12);
        @include mixins.line-height(14);
        text-align: left;
      }
    }

    &.flipped {
      transform:rotateY(180deg);

      .card-front {
        display: none;
      }
    }
  }

  .contributor-image {
    border-radius: 50%;
    $size: 16.8rem;
    height: $size;
    width: $size;
    $min-size: 105px;
    min-height: $min-size;
    min-width: $min-size;
    $max-size: 230px;
    max-height: $max-size;
    max-width: $max-size;
    background-size: cover;
    background-position: center;
    margin: 0.8rem auto;
  }

  p {
    cursor: pointer;
    @include mixins.font-size(14);
    @include mixins.line-height(18);
    margin: 8px 16px;
    text-overflow: ellipsis;
    overflow-y: auto;
    font-weight: 400;
  }
}
